import React, { useState, useContext, useEffect } from "react"
import { ComponentType } from "react"
import { fetchGPTS, fetchIp } from "./ApiVercel.tsx"
import React, { useState, useEffect } from "react"
import { createContext, useContext, useReducer, useRef } from "react"

// Dummy functions simulating external calls
import { fetchGPTS, fetchIp } from "./ApiVercel.tsx"

const numPhrases = 20
const phrases = [
    "...Sabia que a Magma Translation traduz 200 mil palavras todos os dias?",
    "...Temos uma plataforma de traduções com pedidos online, confira.",
    "...Cante como se ninguém estivesse escutando.",
    "Frase 4",
    "Frase 5",
    "Frase 6",
    "Frase 7",
    "Frase 8",
    "Frase 9",
    "Frase 10",
    "Frase 11",
    "Frase 12",
    "Frase 13",
    "Frase 14",
    "Frase 15",
    "Frase 16",
    "Frase 17",
    "Frase 18",
    "Frase 19",
    "Frase 20",
]

const token = 50

const MyContext = React.createContext()

// Context provider component
export function mainContext(Component) {
    return (props) => {
        const [input, setInput] = useState("")
        const [value, setValue] = useState("")
        const [open, setOpen] = useState(false)
        const [ip, setIp] = useState("")

        useEffect(() => {
            localStorage.removeItem("thread")
        }, [])

        useEffect(() => {
            fetchIp().then((res) => setIp(res))
        }, [])

        return (
            <MyContext.Provider
                value={{ input, setInput, value, setValue, open, setOpen, ip }}
            >
                <Component {...props} />
            </MyContext.Provider>
        )
    }
}

// Function to convert URLs to links
function convertTextToLinks(text) {
    const urlRegex = /https?:\/\/[^\s/$.?#].[^\s]*/g
    return text.replace(urlRegex, function (url) {
        const gh = url.split(".").includes("https://app")
        return `<a href="${gh ? "https://app.magmatranslation.com" : "https://magmatranslation.com"}" target="_blank">${url}</a>`
    })
}

// Input component
export function input(Component) {
    return (props) => {
        const { input, setInput } = useContext(MyContext) || {
            input: "",
            setInput: "",
        }

        const handleChange = (e) => {
            setInput(e.target.value)
        }

        return (
            <Component {...props}>
                <textarea
                    className="scroll-container"
                    onChange={handleChange}
                    placeholder={"Pergunte para o site"}
                    style={styleInput}
                />
                <style>{`
                    .scroll-container::-webkit-scrollbar { display: none; }
                    .scroll-container { -ms-overflow-style: none; scrollbar-width: none; }
                `}</style>
            </Component>
        )
    }
}

// Button to trigger GPT and display response in chosen frame
export function button(Component) {
    return (props) => {
        const { input, value, setValue, open, setOpen } = useContext(
            MyContext
        ) || { input: "", value: "", setValue: "", setOpen: false }
        const [selectedFrame, setSelectedFrame] = useState("")

        const handleClick = async () => {
            setOpen(true)
            const data = await fetchGPTS(input, token)
            const generatedText = convertTextToLinks(data.message)

            // Save GPT output to the chosen frame
            const frameElement = document.getElementById(selectedFrame)
            if (frameElement) {
                frameElement.innerHTML = generatedText
                saveToLocalStorage(selectedFrame, generatedText) // Optionally save to localStorage
            }

            setValue(generatedText)
            setOpen(false)
        }

        return (
            <Component {...props}>
                <div>
                    <label htmlFor="frame-select">Choose a frame:</label>
                    <input
                        id="frame-select"
                        type="text"
                        value={selectedFrame}
                        onChange={(e) => setSelectedFrame(e.target.value)}
                    />
                    <button onClick={handleClick}>Generate GPT Content</button>
                </div>
            </Component>
        )
    }
}

// Save content to localStorage for persistence
const saveToLocalStorage = (frameId, content) => {
    localStorage.setItem(frameId, content)
}

// Input styles
const styleInput = {
    width: "80%",
    overflowY: "auto",
    height: "100%",
    outline: "none",
    resize: "none",
    border: "none",
    color: "white",
    backgroundColor: "transparent",
    fontFamily: "Arial",
    fontWeight: "100",
    fontSize: "15px",
    padding: "10px 20px",
    letterSpacing: "",
    lineHeight: "25px",
    wordSpacing: "1px",
    "::placeholder": { color: "white" },
}
